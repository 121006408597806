<template>
  <div>
    <ca-header
      heading="Wiedervorlage bearbeiten"
      :column-layout="columnLayout"
      show-column-layout
      :loading="loading"
      @columnLayout="columnLayout = $event"
    >
      <template #buttonSpace>
        <button
          class="btn btn-primary mr-2"
          @click="save()"
        >
          Speichern <i
            v-if="safePending"
            class="fas fa-circle-notch fa-spin"
          />
        </button>
      </template>
      <template #alert>
        <ca-alert
          v-if="saveErrorMessage"
          variant="danger"
          :message="saveErrorMessage"
          @close="saveErrorMessage = null"
        />
        <ca-alert
          v-if="showFormInvalidHint"
          variant="info"
          message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
          @close="showFormInvalidHint = null"
        />
      </template>
      <template #customLoading>
        <ca-alert
          variant="info"
          spin-icon
          rounded
          icon="circle-notch"
          message="Lade Wiedervorlage..."
        />
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div
          class="col-12"
          :class="{'col-xl-8 col-xxl-6': !columnLayout}"
        >
          <div class="row">
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <resubmit-form
                ref="resubmitForm"
                class="mb-3"
                :resubmission="resubmission"
              />
            </div>
          </div>
          <div
            v-if="!loading"
            class="form-row justify-content-end"
          >
            <button
              class="btn btn-primary mr-2"
              @click="save()"
            >
              Speichern <i
                v-if="safePending"
                class="fas fa-circle-notch fa-spin"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header.vue'
import ResubmitForm from '@/components/Forms/Protocol/ResubmitForm.vue'
import CaAlert from '@/components/Alert.vue'
import resubmitStatus from '@/resources/enums/resubmitStatus'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'ResubmitEdit',
  components: {
    ResubmitForm,
    CaHeader,
    CaAlert
  },
  data () {
    return {
      resubmitStatus,
      resubmission: null,
      loading: false,
      error: null,
      columnLayout: false,
      safePending: false,
      saveError: null,
      saveErrorMessage: null,
      saveSuccess: false,
      showFormInvalidHint: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async save () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.savePending) {
          return
        } else if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.savePending = true
        this.saveError = null
        this.saveErrorMessage = null
        this.showFormInvalidHint = false
        await this.resubmission.save()
        this.saveSuccess = true
      } catch (e) {
        console.log(e)
        this.saveErrorMessage = e.message
        this.saveError = e
      } finally {
        this.savePending = false
        if (this.saveSuccess) {
          this.$router.push({ path: `/resubmission/${this.resubmission._id}` })
        }
      }
    },
    async fetchData () {
      try {
        this.loading = true
        const resubmission = await this.$store.dispatch('resubmission/get', this.$route.params.resubmitId)
        this.resubmission = resubmission.clone()
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
